// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const HomepageSvgSymbol = (props) => (
  <svg
    width={900}
    height={900}
    viewBox="0 0 900 900"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="M537.832 62.72A121.114 121.114 0 0 0 449.999 25a121.114 121.114 0 0 0-87.833 37.72L55.237 407.31C35.714 429.01 24.956 457.188 25 486.388v270.257c0 31.418 12.44 61.507 34.576 83.695A117.903 117.903 0 0 0 143.07 875h94.43c31.299 0 61.36-12.47 83.494-34.659 22.135-22.188 34.576-52.277 34.576-83.695v-94.657c0-39.229 31.698-71.003 70.833-71.003h47.237-.044c39.135 0 70.833 31.774 70.833 71.003v94.657c0 31.418 12.44 61.507 34.576 83.695A117.98 117.98 0 0 0 662.499 875h94.43c31.343 0 61.36-12.47 83.494-34.659C862.558 818.153 875 788.064 875 756.646V486.389c.132-29.067-10.626-57.157-30.238-78.592L537.832 62.72Z"
        fill="#000"
      />
      <path d="M0 0h900v900H0z" />
    </g>
  </svg>
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default HomepageSvgSymbol
