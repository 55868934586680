// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import React from 'react'
import Helmet from 'react-helmet'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import website from '../../seo/website.json'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/**
  * InitializeMeta - Initializes the website with generic meta tags and also configures react-helmet
  *
  * @example
    <InitializeMeta />
  *
  * @version 0.0.1
  * @todo none
  */
const InitializeMeta = () => (
  <Helmet
    htmlAttributes={{ lang: 'en' }}
    titleTemplate={`%s | ${website.name}`}
    meta={[
      // ---- Recommended Minimum
      { name: 'charset', content: 'utf-8' },
      { 'http-equiv': 'x-ua-compatible', content: 'ie=edge' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },

      // ---- Mobile viewport optimization
      { name: 'HandheldFriendly', content: 'True' },
      { name: 'MobileOptimized', content: '320' },

      // ---- Makes sure the website shows up in all countries and languages
      { name: 'coverage', content: 'Worldwide' },
      { name: 'distribution', content: 'Global' },

      // ---- Gives a general age rating based on sites content
      { name: 'rating', content: 'General' },

      // ---- Enable automatic detection and formatting
      { name: 'format-detection', content: 'telephone=yes' },
      { name: 'format-detection', content: 'date=yes' },
      { name: 'format-detection', content: 'address=yes' },
      { name: 'format-detection', content: 'email=yes' },

      // ---- Sends referrer information to secure HTTPS sites, but not insecure HTTP sites
      { name: 'referrer', content: 'no-referrer-when-downgrade' },
    ]}
  />
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default InitializeMeta
