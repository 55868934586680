// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import React from 'react'
import Helmet from 'react-helmet'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import website from '../../seo/website.json'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/**
  * BreadcrumbSchema - Implements schema.org Breadcrumb
  *
  * Describes a webpage
  *
  * @example
    <BreadcrumbSchema data={data}>
  *
  * @param {array} data.breadcrumbs - Required. The url of the page.
  *
  * @version 0.0.1
  * @todo none
  * @see {@link http://schema.org/breadcrumb|Schema.org Breadcrumb Docs}
  */
const BreadcrumbSchema = ({ data = {} }) => {
  const schemaOrgJSONLD = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: data.map(({ name, slug }, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id': `${website.nakedUrlWithIntl}${slug}`,
        url: `${website.nakedUrlWithIntl}${slug}`,
        name,
      },
    })),
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </Helmet>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default BreadcrumbSchema
