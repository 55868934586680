// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

import { updateHeaderState } from '../../state/actions'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
const DesktopHeader = React.memo(({ headerState, updateHeaderState }) => (
  <Fragment>
    <Row justify="space-between">
      <Col xs={0} sm={0} md={0} lg={15} xl={15} xxl={15}>
        <p className="standard">Title</p>
        <nav>
          <Link to="/grid">Grid</Link>, <Link to="/list">List</Link>,{' '}
          <Link to="/contact-form">Contact Form</Link>,{' '}
          <Link to="/newsletter-form">Newsletter Form</Link>,{' '}
          <Link to="/calendar">Calendar</Link>, <Link to="/events">Events</Link>
          , <Link to="/events-list">Events List</Link>,{' '}
          <Link to="/slider">Slider</Link>, <Link to="/gallery">Gallery</Link>
        </nav>
      </Col>
      <Col xs={0} sm={0} md={0} lg={9} xl={9} xxl={9}>
        Etc...
      </Col>
    </Row>
  </Fragment>
))

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    headerState: state.headerState,
  }),
  (dispatch) => ({
    updateHeaderState(payload) {
      dispatch(updateHeaderState(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedDesktopHeader = compose(
  withState // Add state
)(DesktopHeader)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedDesktopHeader
