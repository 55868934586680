// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Layout from '../layout'
import '../layout/style.less'

import UpdateTitle from '../schema/update-title'
import GeneralMetadata from '../schema/general-metadata'
import TwitterSummaryCard from '../schema/twitter-summary-card'
import OpenGraphSummary from '../schema/open-graph-summary'
import WebpageSchema from '../schema/webpage-schema'
import BreadcrumbsSchema from '../schema/breadcrumbs-schema'

import ogX from '../../images/banners/ogX.jpg'
import twitterSummaryX from '../../images/banners/twitterSummaryX.jpg'

import withUrl from '../../methods/with-url'
import removeStopwords from '../../methods/remove-stopwords'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const ogY = withUrl(ogX)
const twitterSummaryY = withUrl(twitterSummaryX)

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Functions
// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** StandardPageWrapper */
const StandardPageWrapper = (props) => {
  const { pageSchema, children, className = '' } = props

  const twitterCover =
    typeof pageSchema.cover === 'undefined'
      ? twitterSummaryY
      : withUrl(pageSchema.cover)
  const ogCover =
    typeof pageSchema.cover === 'undefined' ? ogY : withUrl(pageSchema.cover)

  const keywords = removeStopwords(pageSchema.abstract)

  return (
    <Layout {...props} className={`standard-page ${className}`}>
      <UpdateTitle title={pageSchema.title} />
      <GeneralMetadata
        data={{
          description: pageSchema.abstract,
          keywords,
          image: ogCover,
          slug: pageSchema.slug,
        }}
      />
      <TwitterSummaryCard
        data={{
          title: pageSchema.title,
          slug: pageSchema.slug,
          description: pageSchema.abstract,
          image: twitterCover,
        }}
      />
      <OpenGraphSummary
        data={{
          slug: pageSchema.slug,
          title: pageSchema.title,
          description: pageSchema.abstract,
          image: ogCover,
        }}
      />
      <WebpageSchema
        data={{
          slug: pageSchema.slug,
          name: pageSchema.title,
          abstract: pageSchema.abstract,
          image: ogCover,
          keywords,
        }}
      />
      <BreadcrumbsSchema
        data={pageSchema.breadcrumbs.map((breadcrumb) => ({
          name: breadcrumb.title,
          slug: breadcrumb.slug,
        }))}
      />
      {children}
    </Layout>
  )
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default StandardPageWrapper
