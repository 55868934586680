// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import React from 'react'
import PropTypes from 'prop-types'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** OutLink */
const OutLink = props => (
  <a
    href={props.to}
    target="_blank"
    rel="noopener noreferrer"
    alt={props.to}
    style={props.style}
    className={props.className}
  >
    {props.children}
  </a>
)

OutLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  style: PropTypes.object, // eslint-disable-line
  className: PropTypes.string, // eslint-disable-line
  classes: PropTypes.object, // eslint-disable-line react/forbid-prop-types, react/no-unused-prop-types
}

OutLink.defaultProps = {
  style: {},
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default OutLink
